import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { simpleGetCallWithTokenAxios } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import Loader from "../layouts/loader/Loader";

function ProtectedRoute({ element, permissionName }) {
  const [hasPermission, setHasPermission] = useState(null);

  useEffect(() => {
    async function checkPermission() {
      try {
        const storedResult = localStorage.getItem('pageLinks');
        let userAccessPermissionsList = [];
        let permissionExists = '';
        if (storedResult) {
            userAccessPermissionsList = JSON.parse(storedResult);
            permissionExists = Object.keys(userAccessPermissionsList).some(key => 
              key.toLowerCase() === permissionName.toLowerCase()
          );
          console.log(permissionExists, "permissionExists");
        } else {
            const res = await simpleGetCallWithTokenAxios(ApiConfig.GET_USER_MENU);
            const result = {};
            res.data.forEach(item => {
                item.links.forEach(link => {
                    const pageName = link.module_page.name;
                    const linkUrl = link.module_page.url;
                    result[pageName] = linkUrl;
                });
            });
            localStorage.setItem('pageLinks', JSON.stringify(result));
            userAccessPermissionsList = res.data;
            console.log('No data found in localStorage.');
            permissionExists = userAccessPermissionsList.some(userPermission => {
                const userPermissionName = userPermission?.display_text?.toLowerCase();
                return userPermissionName === permissionName.toLowerCase();
            });    
        }
        setHasPermission(permissionExists);
      } catch (err) {
        console.error("Error fetching user permissions:", err);
        setHasPermission(false);
      }
    }

    checkPermission();
  }, [permissionName]);

  if (hasPermission === null) {
    return <Loader/>;
  }

  if (!hasPermission) {
    return <Navigate to="/auth/404" />;
  }

  return element;
}

ProtectedRoute.propTypes = {
    element: PropTypes.element.isRequired,
    permissionName: PropTypes.string.isRequired,
  };

export default ProtectedRoute;
