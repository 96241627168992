import axios from 'axios'

export async function getResult(data) {
  //console.log('--------------', data);
  return JSON.parse(data.trim());
}

// const base64Credentials = Buffer.from("admin@unisys.com:unisys@2024").toString("base64");
// const credentials = "admin@unisys.com:unisys@2024";
// const headers = {
//   "Authorization": `Basic ${base64Credentials}`
// };
const authorization1 = localStorage.getItem("authorization");
// console.log(authorization1,  "authorization1");
const headers = {
  "Authorization": `JWT ${authorization1}`
};
export async function PostCallWithAuthTokenWithErrorResponse(url, requestBody) {
  let resp;
  const authorization = localStorage.getItem("authorization");

  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: headers.Authorization,

    },
    body: requestBody,
  })
    .then((response) => {
      resp = response;
      return response.json();
    })
    .then((json) => {
      return { response: resp, json, error: !resp.ok };
    })
    .catch((error) => {
      if (error?.response?.status === 401){ //if user is unauthorized or token expires
        window.location.href = "/auth/login"
        localStorage.removeItem("logedIn");
        localStorage.removeItem("authorization");
      }
    })
}
//get api
export async function simpleGetCallWithToken(url) {
  const Token = localStorage.getItem("token");
  return fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: headers.Authorization,
    },
  })
    .then((response) => response.json())
    .then((result) => {
      if (result?.code === 'token_not_valid'){ //if user is unauthorized or token expires
        window.location.href = "/auth/login"
        localStorage.removeItem("logedIn");
        localStorage.removeItem("authorization");
      }
      return result
    })
    .catch((error) => {
      if (error?.response?.status === 401){ //if user is unauthorized or token expires
        window.location.href = "/auth/login"
        localStorage.removeItem("logedIn");
        localStorage.removeItem("authorization");
      }
    })
}

export async function simpleGetCallWithTokenAxios(url) {
  const token = localStorage.getItem("token");
  
  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: headers.Authorization,
      },
    });
    return response;
  } catch (error) {
     if (error?.response?.status === 401){ //if user is unauthorized or token expires
      window.location.href = "/auth/login"
      localStorage.removeItem("logedIn");
      localStorage.removeItem("authorization");
    }
    console.error('Error fetching data:', error);
    return null
    // Handle the error accordingly, e.g., return an error response or rethrow
  }
}

export async function simpleGetCallWithTokenForMenuAxios(url, token) {
  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
    });
    return response;
  } catch (error) {
    if (error?.response?.status === 401){ //if user is unauthorized or token expires
      window.location.href = "/auth/login"
      localStorage.removeItem("logedIn");
      localStorage.removeItem("authorization");
    }
    console.error('Error fetching data:', error);
    return null
    // Handle the error accordingly, e.g., return an error response or rethrow
  }
}
export async function simpleGetCallWithTokenAxiosFile(url) {
  const token = localStorage.getItem('token'); 

  try {
    const response = await axios.get(url, {
      Accept: "application/json",
      "Content-Type": "application/json",
      headers: {
        Authorization: headers.Authorization,
      },
      responseType: 'blob',
    });

    const extractFileName = (contentDisposition) => {
      if (!contentDisposition) return 'downloaded-file'; // Default file name
      const fileName = contentDisposition.split('filename=')[1];
      return fileName ? fileName.replace(/['"]/g, '') : 'downloaded-file';
    };

    return {
      data: response.data,
      fileName: extractFileName(response.headers['content-disposition']),
    };
  } catch (error) {
    if (error?.response?.status === 401){ //if user is unauthorized or token expires
      window.location.href = "/auth/login"
      localStorage.removeItem("logedIn");
      localStorage.removeItem("authorization");
    }
    console.error('Error: ', error);
  }
  return null
};

export async function getIpAxios() {
  try {
    const response = await axios.get('https://freeipapi.com/api/json');
    localStorage.setItem('ip',response.data.ipAddress)
    return response.data.ipAddress;
  } catch (error) {
    console.error('Error fetching IP:', error);
    return localStorage.getItem('ip') || '0.0.0.0'
    // Handle the error accordingly, e.g., return an error response or rethrow
  }
}

export async function simpleGetCallWithTokenForImageAxios(url) {
  const token = localStorage.getItem("token");
  
  try {
    const response = await axios.get(url, {
      responseType: 'blob',
      headers: {
        Authorization: headers.Authorization,
        'Access-Control-Allow-Origin': '*'
      },
    });
    // console.log(response, "response");
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    return null
    // Handle the error accordingly, e.g., return an error response or rethrow
  }
}

export async function PostCallWithErrorResponse(url, requestBody) {
  let resp;
  const authorization = localStorage.getItem("authorization");

  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: headers.Authorization,
    },
    // body: JSON.stringify({...customerData,...requestBody})
    body: requestBody,
  })
    .then((response) => {
      resp = response;
      return response.json();
    })
    .then((json) => {
      if (json?.code === 'token_not_valid'){ //if user is unauthorized or token expires
        window.location.href = "/auth/login"
        localStorage.removeItem("logedIn");
        localStorage.removeItem("authorization");
      }
      return { response: resp, json, error: !resp.ok };
    })
    .catch((error) => {
      if (error?.response?.status === 401){ //if user is unauthorized or token expires
        window.location.href = "/auth/login"
        localStorage.removeItem("logedIn");
        localStorage.removeItem("authorization");
      }
    })
}

export async function DeleteCallWithErrorResponse(url, requestBody) {
  let resp;
  // const [progress, setProgress] = useState(0);
  // const { customerData } = useContext(AppContext);
  const authorization = localStorage.getItem("authorization");
   return fetch(url, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: headers.Authorization,
    },
    // body: JSON.stringify({...customerData,...requestBody})
    body: requestBody,
  })
    .then((response) => {
      resp = response;
      if (resp.status === 204) {
        return resp
      }
      return response.json();
    })
    .then((json) => {
      if (json?.code === 'token_not_valid'){ //if user is unauthorized or token expires
        window.location.href = "/auth/login"
        localStorage.removeItem("logedIn");
        localStorage.removeItem("authorization");
      }
      return { response: resp, json, error: !resp.ok };
    })
    .catch((error) => {
      if (error?.response?.status === 401){ //if user is unauthorized or token expires
        window.location.href = "/auth/login"
        localStorage.removeItem("logedIn");
        localStorage.removeItem("authorization");
      }
    })
}

export async function DeleteCallWithErrorResponseAxios(url, requestBody) {
  try {
    const response = await axios.delete(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: headers.Authorization,
      },
      data: requestBody,
    });

    return { response, json: response.data, error: false };
  } catch (error) {
    if (error?.response?.status === 401){ //if user is unauthorized or token expires
      window.location.href = "/auth/login"
      localStorage.removeItem("logedIn");
      localStorage.removeItem("authorization");
    }
    if (error.response && error.response.status === 204) {
      return { response: error.response, json: null, error: false };
    }
    return { response: error.response, json: error.response ? error.response.data : null, error: true };
  }
}

export async function deleteWithAuthCall(url, requestBody) {
  return fetch(url, {
    method: "DELETE",

    headers: {
      Accept: "application/json",

      "Content-Type": "application/json",
      Authorization: `Token${localStorage.getItem("auth_token")}`,
    },

    body: requestBody,
  }).then((response) => response.json()).catch((error) => {
    if (error?.response?.status === 401){ //if user is unauthorized or token expires
      window.location.href = "/auth/login"
      localStorage.removeItem("logedIn");
      localStorage.removeItem("authorization");
    }
  })
}

export async function multipartPostCallWithErrorResponse(url, requestBody) {
  let resp;
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: headers.Authorization,
    },
    body: requestBody,
  })
    .then((response) => {
      resp = response;
      return response.json();
    })
    .then((json) => {
      if (json?.code === 'token_not_valid'){ //if user is unauthorized or token expires
        window.location.href = "/auth/login"
        localStorage.removeItem("logedIn");
        localStorage.removeItem("authorization");
      }
      return { response: resp, json, error: !resp.ok };

    })
    .catch((error) => {
      if (error?.response?.status === 401){ //if user is unauthorized or token expires
        window.location.href = "/auth/login"
        localStorage.removeItem("logedIn");
        localStorage.removeItem("authorization");
      }
    })
}

export async function multipartPostCallWithErrorResponseAxios(url, requestBody) {
  try {
    const response = await axios.post(url, requestBody, {
      headers: {
        Accept: "application/json",
        Authorization: headers.Authorization,
        'Content-Type': 'multipart/form-data'
      },
    });

    return { response, json: response.data, error: false };
  } catch (error) {
    if (error?.response?.status === 401){ //if user is unauthorized or token expires
      window.location.href = "/auth/login"
      localStorage.removeItem("logedIn");
      localStorage.removeItem("authorization");
    }
    return { response: error.response, json: error.response ? error.response.data : null, error: true };
  }
}

export async function multipartPutCallWithErrorResponse(url, requestBody) {
  let resp;
  const authorization = localStorage.getItem("authorization");
  return fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      //"Content-Type": "multipart/form-data",
      Authorization: headers.Authorization,
    },
    body: requestBody,
  })
    .then((response) => {
      resp = response;
      return response.json();
    })
    .then((json) => {
      if (json?.code === 'token_not_valid'){ //if user is unauthorized or token expires
        window.location.href = "/auth/login"
        localStorage.removeItem("logedIn");
        localStorage.removeItem("authorization");
      }
      return { response: resp, json, error: !resp.ok };
    })
    .catch((error) => {
      if (error?.response?.status === 401){ //if user is unauthorized or token expires
        window.location.href = "/auth/login"
        localStorage.removeItem("logedIn");
        localStorage.removeItem("authorization");
      }
    })
}

export async function multipartPatchCallWithErrorResponse(url, requestBody) {
  let resp;
  return fetch(url, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      
      Authorization: headers.Authorization,
    },
    body: requestBody,
  })
    .then((response) => {
      resp = response;
      return response.json();
    })
    .then((json) => {
      if (json?.code === 'token_not_valid'){ //if user is unauthorized or token expires
        window.location.href = "/auth/login"
        localStorage.removeItem("logedIn");
        localStorage.removeItem("authorization");
      }
      return { response: resp, json, error: !resp.ok };

    })
    .catch((error) => {
      if (error?.response?.status === 401){ //if user is unauthorized or token expires
        window.location.href = "/auth/login"
        localStorage.removeItem("logedIn");
        localStorage.removeItem("authorization");
      }
    })
}

export async function multipartPatchCallWithErrorResponseAxios(url, requestBody) {
  try {
    const response = await axios.patch(url, requestBody, {
      headers: {
        Accept: "application/json",
        Authorization: headers.Authorization,
        'Content-Type': 'multipart/form-data',
      },
    });

    return { response, json: response.data, error: false };
  } catch (error) {
    if (error?.response?.status === 401){ //if user is unauthorized or token expires
      window.location.href = "/auth/login"
      localStorage.removeItem("logedIn");
      localStorage.removeItem("authorization");
    }
    return { response: error.response, json: error.response ? error.response.data : null, error: true };
  }
}

export async function getWithAuthCallWithErrorResponse(url) {
  let resp;
  return fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token${localStorage.getItem("USER_AUTH_TOKEN")}`,
    },
  })
    .then((response) => {
      resp = response;
      return response.json();
    })
    .then((json) => {
      if (json?.code === 'token_not_valid'){ //if user is unauthorized or token expires
        window.location.href = "/auth/login"
        localStorage.removeItem("logedIn");
        localStorage.removeItem("authorization");
      }
      return { response: resp, json, error: !resp.ok };

    })
    .catch((error) => {
      if (error?.response?.status === 401){ //if user is unauthorized or token expires
        window.location.href = "/auth/login"
        localStorage.removeItem("logedIn");
        localStorage.removeItem("authorization");
      }
    })
}

export async function postWithAuthCallWithErrorResponse(url, requestBody) {
  let resp;
  return fetch(url, {
    method: "POST",
    mode: "cors",
    headers: {
      Accept: "application/json",
      // "Content-Type": "application/json",
      // "Content-Type": "multipart/form-data",
      // Authorization: "Token " + localStorage.getItem("api_key"),
    },
    body: requestBody,
  })
    .then((response) => {
      resp = response;
      return response.json();
    })
    .then((json) => {
      if (json?.code === 'token_not_valid'){ //if user is unauthorized or token expires
        window.location.href = "/auth/login"
        localStorage.removeItem("logedIn");
        localStorage.removeItem("authorization");
      }
      return { response: resp, json, error: !resp.ok };

    })
    .catch((error) => {
      if (error?.response?.status === 401){ //if user is unauthorized or token expires
        window.location.href = "/auth/login"
        localStorage.removeItem("logedIn");
        localStorage.removeItem("authorization");
      }
    })
}

export async function putMultipartWithAuthCallWithErrorResponse(
  url,
  requestBody
) {
  let resp;
  const authorization = localStorage.getItem("authorization");
  return fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      //'Content-Type': 'multipart/form-data',
      Authorization: authorization
    },
    body: requestBody,
  })
    .then((response) => {
      resp = response;
      return response.json();
    })
    .then((json) => {
      if (json?.code === 'token_not_valid'){ //if user is unauthorized or token expires
        window.location.href = "/auth/login"
        localStorage.removeItem("logedIn");
        localStorage.removeItem("authorization");
      }
      return { response: resp, json, error: !resp.ok };

    })
    .catch((error) => {
      if (error?.response?.status === 401){ //if user is unauthorized or token expires
        window.location.href = "/auth/login"
        localStorage.removeItem("logedIn");
        localStorage.removeItem("authorization");
      }
    })
}

export async function putMultipartWithAuthCallWithErrorResponsePlane(
  url,
  requestBody
) {
  let resp;
  const authorization = localStorage.getItem("authorization");
  return fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      'Content-Type': 'application/json',
      Authorization: authorization
    },
    body: requestBody,
  })
    .then((response) => {
      resp = response;
      return response.json();
    })
    .then((json) => {
      if (json?.code === 'token_not_valid'){ //if user is unauthorized or token expires
        window.location.href = "/auth/login"
        localStorage.removeItem("logedIn");
        localStorage.removeItem("authorization");
      }
      return { response: resp, json, error: !resp.ok };

    })
    .catch((error) => {
      if (error?.response?.status === 401){ //if user is unauthorized or token expires
        window.location.href = "/auth/login"
        localStorage.removeItem("logedIn");
        localStorage.removeItem("authorization");
      }
    })
}

export async function postMultipartWithAuthCallWithErrorResponse(
  url,
  requestBody
) {
  let resp;
  return fetch(url, {
    method: "POST",

    headers: {
      Accept: "application/json",
      Authorization: `Token${localStorage.getItem("USER_AUTH_TOKEN")}`,
    },
    body: requestBody,
  })
    .then((response) => {
      resp = response;
      return response.json();
    })
    .then((json) => {
      if (json?.code === 'token_not_valid'){ //if user is unauthorized or token expires
        window.location.href = "/auth/login"
        localStorage.removeItem("logedIn");
        localStorage.removeItem("authorization");
      }
      return { response: resp, json, error: !resp.ok };

    })
    .catch((error) => {
      if (error?.response?.status === 401){ //if user is unauthorized or token expires
        window.location.href = "/auth/login"
        localStorage.removeItem("logedIn");
        localStorage.removeItem("authorization");
      }
    })
}

export async function simpleGetCallWithErrorResponse(url) {
  let resp;
  const authorization = localStorage.getItem("authorization");

  return fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: headers.Authorization,
    },

  })
    .then((response) => {
      resp = response;
      return response.json();
    })
    .then((json) => {
      if (json?.code === 'token_not_valid'){ //if user is unauthorized or token expires
        window.location.href = "/auth/login"
        localStorage.removeItem("logedIn");
        localStorage.removeItem("authorization");
      }
      return { response: resp, json, error: !resp.ok };

    })
    .catch((error) => {
      if (error?.response?.status === 401){ //if user is unauthorized or token expires
        window.location.href = "/auth/login"
        localStorage.removeItem("logedIn");
        localStorage.removeItem("authorization");
      }
    })
}

export async function simpleGetCall(url) {
  return fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.text())
    .then((result) => getResult(result))
    .catch((error) => {
      if (error?.response?.status === 401){ //if user is unauthorized or token expires
        window.location.href = "/auth/login"
        localStorage.removeItem("logedIn");
        localStorage.removeItem("authorization");
      }
    })
}

export async function simplePostCall(url, requestBody) {
  return fetch(url, {
    method: "POST",
    // mode: "cors",
    headers: {
      Accept: "application/json",
      // "Content-Type": "application/json",
      // Origin: window.location.origin,
      //'Access-Control-Allow-Credentials': "*"
    },
    withCredentials: true,
    body: requestBody,
  })
    .then((response) => response.text())
    .then((result) => getResult(result))
    .catch((error) => {
      if (error?.response?.status === 401){ //if user is unauthorized or token expires
        window.location.href = "/auth/login"
        localStorage.removeItem("logedIn");
        localStorage.removeItem("authorization");
      }
    })
  //.then(data=>data.json());
}

export async function multipartPostCall(url, requestBody) {
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      //'Content-Type': 'multipart/form-data',
      // 'Authorization': 'Token ' +  AsyncStorage.getItem(AppStrings.TOKEN)
    },
    body: requestBody,
  })
    .then((response) => response.text())
    .then((result) => getResult(result))
    .catch((error) => {
      if (error?.response?.status === 401){ //if user is unauthorized or token expires
        window.location.href = "/auth/login"
        localStorage.removeItem("logedIn");
        localStorage.removeItem("authorization");
      }
    })
}

export async function getWithAuthCall(url) {
  return fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      //Authorization: 'Token ' + ( AsyncStorage.getItem(AppStrings.TOKEN)),
    },
  })
    .then((response) => response.text())
    .then((result) => getResult(result))
    .catch((error) => {
      if (error?.response?.status === 401){ //if user is unauthorized or token expires
        window.location.href = "/auth/login"
        localStorage.removeItem("logedIn");
        localStorage.removeItem("authorization");
      }
    })
}

export async function postWithAuthCall(url, requestBody) {
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      // "Content-Type": "application/json",
      // Authorization: 'Token ' + ( AsyncStorage.getItem(AppStrings.TOKEN)),
    },
    body: requestBody,
  })
    .then((response) => response.text())
    .then((result) => getResult(result))
    .catch((error) => {
      if (error?.response?.status === 401){ //if user is unauthorized or token expires
        window.location.href = "/auth/login"
        localStorage.removeItem("logedIn");
        localStorage.removeItem("authorization");
      }
    })
}

export async function putWithAuthCall(url, requestBody) {
  return fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      //Authorization: 'Token ' + ( AsyncStorage.getItem(AppStrings.TOKEN)),
    },
    body: requestBody,
  })
    .then((response) => response.text())
    .then((result) => getResult(result))
    .catch((error) => {
      if (error?.response?.status === 401){ //if user is unauthorized or token expires
        window.location.href = "/auth/login"
        localStorage.removeItem("logedIn");
        localStorage.removeItem("authorization");
      }
    })
}

export async function postMultipartWithAuthCall(url, requestBody) {
  return fetch(url, {
    method: "POST",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
    },
    withCredentials: true,
    body: requestBody,
  })
    .then((response) => response.text())
    .then((result) => getResult(result))
    .catch((error) => {
      if (error?.response?.status === 401){ //if user is unauthorized or token expires
        window.location.href = "/auth/login"
        localStorage.removeItem("logedIn");
        localStorage.removeItem("authorization");
      }
    })
}

export async function putMultipartWithAuthCall(url, requestBody) {
  return fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      //Authorization: 'Token ' + ( AsyncStorage.getItem(AppStrings.TOKEN)),
    },
    body: requestBody,
  })
    .then((response) => response.text())
    .then((result) => getResult(result))
    .catch((error) => {
      if (error?.response?.status === 401){ //if user is unauthorized or token expires
        window.location.href = "/auth/login"
        localStorage.removeItem("logedIn");
        localStorage.removeItem("authorization");
      }
    })
}
export async function updateProfile(url, requestBody) {
  return fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      // Authorization: 'Token ' + ( AsyncStorage.getItem(AppStrings.token)),
    },
    body: requestBody,
  })
    .then((response) => response.text())
    .then((result) => getResult(result))
    .catch((error) => {
      if (error?.response?.status === 401){ //if user is unauthorized or token expires
        window.location.href = "/auth/login"
        localStorage.removeItem("logedIn");
        localStorage.removeItem("authorization");
      }
    })
}


