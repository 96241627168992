// export const BASE_URL = "http://35.188.156.151";
// export const BASE_URL = "http://34.18.67.112/";
export const BASE_URL = 'https://unisys.online/';
// export const BASE_URL = "https://api.wifiattendance.com/";
const ApiConfig = {
  BASE_URL_FOR_IMG: 'http://34.18.67.112',

  LOGIN: `${BASE_URL}api/login/`,
  TOKEN: `${BASE_URL}api/token/`,

  // University
  SECTORLIST: `${BASE_URL}api/sectors/`,
  LOCALITIESLIST: `${BASE_URL}api/localities/`,
  ADDUNIVERSITY: `${BASE_URL}api/universities/`,
  UPDATEUNIVERSITY: `${BASE_URL}api/universities/`,
  UNIVERSITYDETAILS: `${BASE_URL}api/universities/`,
  UNIVERSITYDETELE: `${BASE_URL}api/universities/`,
  UNIVERSITYlIST: `${BASE_URL}api/universities/`,
  DELETE_UNIVERSITY: `${BASE_URL}api/universities/{id}/`,
  // Campus
  COUNTERYLIST: `${BASE_URL}api/countries/`,
  COUNTRYLISTALL: `${BASE_URL}api/countries/all/`,
  REGIONLIST: `${BASE_URL}api/regions/`,
  REGIONLISTALL: `${BASE_URL}api/regions/all/?country=`,
  CITYLIST: `${BASE_URL}api/cities/`,
  CITYLISTALL: `${BASE_URL}api/cities/all/?region=`,
  CAMPUSLIST: `${BASE_URL}api/campuses/`,
  CAMPUSDETAILS: `${BASE_URL}api/campuses/`,
  ADDCAMPUS: `${BASE_URL}api/campuses/`,
  UPDATECAMPUS: `${BASE_URL}api/campuses/`,
  DELETE_CAMPUS: `${BASE_URL}api/campuses/{id}/`,
  GETCAMPUSESBYUNIVERSITY: `${BASE_URL}api/campuses/?university=`,

  // Faculty API
  FACULTYLIST: `${BASE_URL}api/faculties/`,
  ADDFACULTY: `${BASE_URL}api/faculties/`,
  UPDATEFACULTY: `${BASE_URL}api/faculties/`,
  FACULTYDETAILS: `${BASE_URL}api/faculties/`,
  DELETE_FACULTIES: `${BASE_URL}api/faculties/{id}/`,
  GETFACULTIESBYUNIVERSITY : `${BASE_URL}api/faculties/?university=`,
  GETUNASSIGNEDFACULTIESBYCAMPUS : `${BASE_URL}api/faculties/list_unassigned_faculties/{id}/`,

  // Program
  PROGRAMLEVELLIST: `${BASE_URL}api/program-levels/`,
  PROGRAMMODE: `${BASE_URL}api/program-modes/`,
  PROGRAMSTUDTYLIST: `${BASE_URL}api/study-types/`,
  PROGRAMLSIT: `${BASE_URL}api/programs/`,
  ADDPROGRAM: `${BASE_URL}api/programs/`,
  UPDATEPROGRAM: `${BASE_URL}api/programs/`,
  PROGRAMDETAILS: `${BASE_URL}api/programs/`,
  PROGRAMDETELE: `${BASE_URL}api/programs/`,
  DELETE_PROGRAMS: `${BASE_URL}api/programs/{id}/`,
  GETPROGRAMSBYCAMPUS: `${BASE_URL}api/campus-program/?faculty__campus=`,
  GETPROGRAMSBYUNIVERSITY : `${BASE_URL}api/programs/?university=`,
  GETPROGRAMSBYUNIVERSITYFACULTY : `${BASE_URL}api/programs/?university={universityId}&faculty={facultyId}`,
  GETPROGRAMSBYFACULTY : `${BASE_URL}api/programs/?faculty=`,
  GETUNASSIGNEDPROGRAMSBYFACULTY : `${BASE_URL}api/programs/list_unassigned_programs/{id}/`,

  //  Campus Faculties
  CAMPUS_FACULTIES_LIST: `${BASE_URL}api/campus-faculties/`,
  ADD_CAMPUS_FACULTIES: `${BASE_URL}api/campus-faculties/`,
  CAMPUS_FACULTIES_DETAILS: `${BASE_URL}api/campus-faculties/{id}/`,
  UPDATE_CAMPUS_FACULTIES: `${BASE_URL}api/campus-faculties/{id}/`,
  DELETE_CAMPUS_FACULTY : `${BASE_URL}api/campus-faculties/{id}/`,
  GETCAMPUSFACULTIESBYCAMPUS: `${BASE_URL}api/campus-faculties/?campus=`,

  // Campus Program
  GETCAMPUSPROGRAMSBYCAMPUS: `${BASE_URL}api/campus-program/?faculty__campus=`,



  //   Application Status
  APPLICATION_STATUSES: `${BASE_URL}api/application-status/`,

  //   Verification Status
  VERIFICATION_STATUSES: `${BASE_URL}api/verification-statuses/`,

  //   Attachment
  ATTACHMENTS: (page, pageSize) => `${BASE_URL}api/attachments/?page=${page}&page_size=${pageSize}`,
  ALL_ATTACHMENTS: `${BASE_URL}api/attachments/`,
  ADD_ATTACHMENT: `${BASE_URL}api/attachments/`,
  UPDATE_ATTACHMENT: `${BASE_URL}api/attachments/`,
  ALL_ATTACHMENT_TYPES: `${BASE_URL}api/attachments/?no_page=true`,

  //   Contact Types List
  CONTACT_TYPE_LIST: `${BASE_URL}api/contact-types/`,

  //   CURRENCY
  ADD_CURRENCIES: `${BASE_URL}api/currencies/`,
  UPDATE_CURRENCIES: `${BASE_URL}api/currencies/`,
  CURRENCIES: (page, pageSize) => `${BASE_URL}api/currencies/?page=${page}&page_size=${pageSize}`,
  CURRENCIES_ALL: `${BASE_URL}api/currencies/?no_page=true`,

  //   Language
  LANGUAGE: (page, pageSize) => `${BASE_URL}api/languages/?page=${page}&page_size=${pageSize}`,
  ADD_LANGUAGE: `${BASE_URL}api/languages/`,
  UPDATE_LANGUAGE: `${BASE_URL}api/languages/`,
  LANGUAGES_ALL: `${BASE_URL}api/languages/?no_page=true`,
  GET_LANGUAGES_ALL: `${BASE_URL}api/languages/?no_page=true`,

  // RESEARCH LANGUAGE
  ADD_RESEARCH_LANGUAGE: `${BASE_URL}api/research-languages/`,
  DELETE_RESEARCH_LANGUAGE: `${BASE_URL}api/research-languages/{id}/`,
  UPDATE_RESEARCH_LANGUAGE: `${BASE_URL}api/research-languages/{id}/`,
  RESEARCH_LANGUAGE_DETAILS: `${BASE_URL}api/research-languages/{id}/`,

  //   Language Proficiency
  LANGUAGE_PROFICIENCY: `${BASE_URL}api/proficiency/`,

  //   Maritial Status
  MARITIAL_STATUSES: `${BASE_URL}api/marital-statuses/`,

  //   Payment Modes
  PAYMENT_MODES: `${BASE_URL}api/payment-modes/`,

  //   Religion
  RELIGIONS: (page, pageSize) => `${BASE_URL}api/religions/?page=${page}&page_size=${pageSize}`,
  ADD_RELIGION: `${BASE_URL}api/religions/`,
  UPDATE_RELIGION: `${BASE_URL}api/religions/`,
  RELIGIONS_ALL: `${BASE_URL}api/religions/?no_page=true`,

  //   Countries
  COUNTRIES: (page, pageSize) => `${BASE_URL}api/countries/?page=${page}&page_size=${pageSize}`,
  ADD_COUNTRY: `${BASE_URL}api/countries/`,
  UPDATE_COUNTRY: `${BASE_URL}api/countries/`,

  //   Cities
  CITIES: (page, pageSize) => `${BASE_URL}api/cities/?page=${page}&page_size=${pageSize}`,
  GET_CITIES_BY_COUNTRY: `${BASE_URL}api/cities/all/?country=`,
  GET_CITIES_BY_STATE: `${BASE_URL}api/cities/all/?region=`,

  //   States
  STATES: (page, pageSize) => `${BASE_URL}api/regions/?page=${page}&page_size=${pageSize}`,
  GET_STATES_BY_COUNTRY: `${BASE_URL}api/regions/all/?country=`,

  //   Program Mode
  PROGRAM_MODE: `${BASE_URL}api/program-modes/`,

  //   Program Level
  PROGRAM_LEVEL: `${BASE_URL}api/program-levels/`,

  //   campus-program
  CAMPUSPROGRAMLIST: `${BASE_URL}api/campus-program/`,

  //   Qualification
  QUALIFICATIONS: `${BASE_URL}api/qualifications/`,

  //   Qualification Level
  QUALIFICATION_LEVEL: `${BASE_URL}api/qualification-levels/`,

  //   Student Type
  STUDENT_TYPE: `${BASE_URL}api/student-types/`,

  //   Study Type
  STUDY_TYPE: `${BASE_URL}api/study-types/`,

  //   Course Type
  COURSE_TYPE: `${BASE_URL}api/course-type/`,

  //   Agent Type
  AGENT_TYPE: `${BASE_URL}api/agent-type/`,

  // Agent Level
  AGENT_LEVELS_LIST: `${BASE_URL}api/agent-level/`,
  ADD_AGENT_LEVEL: `${BASE_URL}api/agent-level/`,
  UPDATE_AGENT_LEVEL: `${BASE_URL}api/agent-level/{id}/`,
  DELETE_AGENT_LEVEL: `${BASE_URL}api/agent-level/{id}/`,
  GET_AGENT_LEVEL: `${BASE_URL}api/agent-level/{id}/`,

  // Agent Level Rate
  AGENT_LEVEL_RATES_LIST: `${BASE_URL}api/agent-level-rate/`,
  ADD_AGENT_LEVEL_RATE: `${BASE_URL}api/agent-level-rate/`,
  UPDATE_AGENT_LEVEL_RATE: `${BASE_URL}api/agent-level-rate/{id}/`,
  DELETE_AGENT_LEVEL_RATE: `${BASE_URL}api/agent-level-rate/{id}/`,
  GET_AGENT_LEVEL_RATE: `${BASE_URL}api/agent-level-rate/{id}/`,

  //Intake Program
  INTAKE_PROGRAMS_LIST: `${BASE_URL}api/intake-programs/`,

  //   Grad Application Type List
  GRAD_APPLICATION_TYPE: `${BASE_URL}api/grad-application-types/`,

  //   Application Type Check List
  APPLICATION_TYPE_CHECKLIST: `${BASE_URL}api/application-type-checklists/`,

  //   Grad Activity Types
  GRAD_ACTIVITY_TYPE: `${BASE_URL}api/grad-activity-types/`,

  //   Manage Modules
  MODULE: (page, pageSize) => `${BASE_URL}api/module/?page=${page}&page_size=${pageSize}`,
  DELETE_MODULE: `${BASE_URL}api/module/{id}/`,
  UPDATE_MODULE: `${BASE_URL}api/module/`,
  ADD_MODULE: `${BASE_URL}api/module/`,
  ALL_MODULE: `${BASE_URL}api/module/?no_page=true`,

  //   Manage Modules Pages
  PAGE: (page, pageSize, moduleId) =>
    `${BASE_URL}api/page/?page=${page}&page_size=${pageSize}&module=${moduleId}`,
  GET_PAGE: `${BASE_URL}api/page/{id}/`,
  DELETE_PAGE: `${BASE_URL}api/page/{id}/`,
  UPDATE_PAGE: `${BASE_URL}api/page/`,
  ADD_PAGE: `${BASE_URL}api/page/`,
  ALL_PAGE: `${BASE_URL}api/page/?no_page=true`,

  //   Manage Group Menus
  GROUP_MENU: (page, pageSize, groupId) => `${BASE_URL}api/group-menu/?page=${page}&page_size=${pageSize}&group=${groupId}`,
  GROUP_MENU_LIST : `${BASE_URL}api/group-menu/?no_page=true`,
  GROUP_MENU_LIST_BY_GROUP : `${BASE_URL}api/group-menu/?no_page=true&group={groupId}`,
  DELETE_GROUP_MENU : `${BASE_URL}api/group-menu/{id}/`,
  UPDATE_GROUP_MENU : `${BASE_URL}api/group-menu/`,
  ADD_GROUP_MENU : `${BASE_URL}api/group-menu/`,

  //   User Group
  USER_GROUP: `${BASE_URL}api/group/`,
  USER_GROUP_ALL: `${BASE_URL}api/group/?no_page=true`,

  //   Manage Group Menu Links
  GROUP_MENU_LINK: (page, pageSize, groupId, groupMenuId) =>
    `${BASE_URL}api/link/?page=${page}&page_size=${pageSize}&group_menu__group=${groupId}&group_menu=${groupMenuId}`,
  GROUP_MENU_LINK_LINK: (groupMenu) => `${BASE_URL}api/link/?no_page=true&group_menu=${groupMenu}`,
  ALL_GROUP_MENU_LINK: `${BASE_URL}api/link/?no_page=true`,
  ADD_GROUP_MENU_LINK: `${BASE_URL}api/link/`,
  UPDATE_GROUP_MENU_LINK: `${BASE_URL}api/link/`,
  GET_GROUP_MENU_LINK: `${BASE_URL}api/link/{id}/`,
  DELETE_GROUP_MENU_LINK: `${BASE_URL}api/link/{id}/`,
  LIST_UNASSIGNED_MODULE_PAGES: `${BASE_URL}api/page/list_unassigned_module_pages/`,

  //   Manage User InActive Menu
  INACTIVE_MENU: (page, pageSize) =>
    `${BASE_URL}api/inactive-menu/?page=${page}&page_size=${pageSize}`,
  USER_INACTIVE_MENU: (user) => `${BASE_URL}api/inactive-menu/?no_page=true&user=${user}`,
  UPDATE_INACTIVE_MENU: `${BASE_URL}api/inactive-menu/`,
  DELETE_INACTIVE_MENU: `${BASE_URL}api/inactive-menu/{id}/`,
  ADD_INACTIVE_MENU: `${BASE_URL}api/inactive-menu/`,
  LIST_UNASSIGNED_GROUP_MENU: `${BASE_URL}api/group-menu/list_unassigned_group_menu/{id}/`,

  //   Manage User InActive Link
  INACTIVE_LINK: (page, pageSize) =>
    `${BASE_URL}api/inactive-link/?page=${page}&page_size=${pageSize}`,
  UPDATE_INACTIVE_LINK: `${BASE_URL}api/inactive-link/`,
  DELETE_INACTIVE_LINK: `${BASE_URL}api/inactive-link/{id}/`,
  ADD_INACTIVE_LINK: `${BASE_URL}api/inactive-link/`,

  //   Users
  GET_USERS : `${BASE_URL}api/users/`,
  GET_USER_MENU : `${BASE_URL}api/get-user-menu/`,

  //Menu Management
  GET_DYNAMIC_USER_MENU_FOR_NON_ADMIN : `${BASE_URL}api/menu-structure/`,

  // Grad module - Defense
  ACADEMIC_SESSIONS: `${BASE_URL}api/campus-academic-sessions/`,

  DEFENSE_VENUES: `${BASE_URL}api/defense-venues/`,
  DEFENSE_DETAILS: `${BASE_URL}api/defense/{id}/`,
  ADD_DEFENSE: `${BASE_URL}api/defense/`,
  UPDATE_DEFENSE: `${BASE_URL}api/defense/{id}/`,

  // Grad module - Defense Type
  DEFENSE_TYPES: `${BASE_URL}api/defense-types/`,
  ADD_DEFENSE_TYPE: `${BASE_URL}api/defense-types/`,
  GET_DEFENSE_TYPE: `${BASE_URL}api/defense-types/{id}/`,
  UPDATE_DEFENSE_TYPE: `${BASE_URL}api/defense-types/{id}/`,
  DELETE_DEFENSE_TYPE: `${BASE_URL}api/defense-types/{id}/`,

  // Grad module - Defense Statuses
  DEFENSE_STATUSES: `${BASE_URL}api/defense-status/`,
  ADD_DEFENSE_STATUS: `${BASE_URL}api/defense-status/`,
  GET_DEFENSE_STATUS: `${BASE_URL}api/defense-status/{id}/`,
  UPDATE_DEFENSE_STATUS: `${BASE_URL}api/defense-status/{id}/`,
  DELETE_DEFENSE_STATUS: `${BASE_URL}api/defense-status/{id}/`,

  // Grad module - Research Statuses
  RESEARCH_STATUSES: `${BASE_URL}api/research-statuses/`,
  ADD_RESEARCH_STATUS: `${BASE_URL}api/research-statuses/`,
  GET_RESEARCH_STATUS: `${BASE_URL}api/research-statuses/{id}/`,
  UPDATE_RESEARCH_STATUS: `${BASE_URL}api/research-statuses/{id}/`,
  DELETE_RESEARCH_STATUS: `${BASE_URL}api/research-statuses/{id}/`,

  // Grad module - Defense Evaluator
  DEFENSE_EVALUATOR: `${BASE_URL}api/defense-evaluator/`,
  ADD_DEFENSE_EVALUATOR: `${BASE_URL}api/defense-evaluator/`,
  GET_DEFENSE_EVALUATOR: `${BASE_URL}api/defense-evaluator/{id}/`,
  UPDATE_DEFENSE_EVALUATOR: `${BASE_URL}api/defense-evaluator/{id}/`,
  DELETE_DEFENSE_EVALUATOR: `${BASE_URL}api/defense-evaluator/{id}/`,

  //Grad Module - Defense Venue
  ADD_DEFENSE_VENUE: `${BASE_URL}api/defense-venues/`,
  DEFENSE_VENUES_LIST: `${BASE_URL}api/defense-venues/`,
  UPDATE_DEFENSE_VENUE: `${BASE_URL}api/defense-venues/{id}/`,
  DELETE_DEFENSE_VENUE: `${BASE_URL}api/defense-venues/{id}/`,
  DEFENSE_VENUE_DETAILS: `${BASE_URL}api/defense-venues/{id}/`,

  //Grad Module - Research Areas
  ADD_RESEARCH_AREA: `${BASE_URL}api/research-areas/`,
  RESEARCH_AREAS_LIST: `${BASE_URL}api/research-areas/`,
  UPDATE_RESEARCH_AREA: `${BASE_URL}api/research-areas/{id}/`,
  DELETE_RESEARCH_AREA: `${BASE_URL}api/research-areas/{id}/`,
  RESEARCH_AREA_DETAILS: `${BASE_URL}api/research-areas/{id}/`,

  // Reasearch Activities
  ALL_RESEARCH_ACTIVITY: `${BASE_URL}api/research-activities/`,
  ADD_RESEARCH_ACTIVITY: `${BASE_URL}api/research-activities/`,
  UPDATE_RESEARCH_ACTIVITY: `${BASE_URL}api/research-activities/`,
  GET_RESEARCH_ACTIVITY: `${BASE_URL}api/research-activities/{id}/`,
  DELETE_RESEARCH_ACTIVITY: `${BASE_URL}api/research-activities/{id}/`,

  // Researches
  ADD_RESEARCHES: `${BASE_URL}api/researches/`,
  RESEARCH_APPLICATION: `${BASE_URL}api/research-applications/`,
  GET_STUDENT_USERS: `${BASE_URL}api/student/`,
  RESEARCH_AREA: `${BASE_URL}api/research-areas/`,
  RESEARCH_SUPERVISOR: `${BASE_URL}api/research-supervisors/`,
  ADD_RESEARCH_SUPERVISOR: `${BASE_URL}api/research-supervisors/`,
  DELETE_RESEARCH_SUPERVISOR: `${BASE_URL}api/research-supervisors/{id}/`,
  GET_SUPERVISORS_BY_RESEARCH: `${BASE_URL}api/research-supervisors/?research=`,
  ALL_SUPERVISOR: `${BASE_URL}api/oa-supervisors/`,
  SUPERVISOR_TYPE: `${BASE_URL}api/supervisor-types/`,
  RESEARCH_LANGUAGES: `${BASE_URL}api/research-languages/`,
  RESEARCHES: `${BASE_URL}api/researches/`,
  UPDATE_RESEARCHES: `${BASE_URL}api/researches/{id}/`,
  GET_RESEARCH: `${BASE_URL}api/researches/{id}/`,

  // Application Interface Page
  RESEARCH_APPLICATIONS_PENDING: `${BASE_URL}api/research-applications/?filter=pending`,
  RESEARCH_APPLICATIONS_REJECTED: `${BASE_URL}api/research-applications/?filter=rejected`,
  RESEARCH_APPLICATIONS_APPROVED: `${BASE_URL}api/research-applications/?filter=approved`,

  // Researchers
  RESEARCHERS: `${BASE_URL}api/researchers/`,
  ADD_RESEARCHER: `${BASE_URL}api/researchers/`,
  GET_RESEARCHER: `${BASE_URL}api/researchers/{id}/`,
  UPDATE_RESEARCHER: `${BASE_URL}api/researchers/{id}/`,
  DELETE_RESEARCHER: `${BASE_URL}api/researchers/{id}/`,
  ADD_RESEARCHER_DETAIL: `${BASE_URL}api/researcher-detail/`,
  UPDATE_RESEARCHER_DETAIL: `${BASE_URL}api/researcher-detail/{id}/`,

  //Grad Module - Research Structures
  RESEARCH_STRUCTURES_LIST: `${BASE_URL}api/research-structures/`,
  UPDATE_RESEARCH_STRUCTURE: `${BASE_URL}api/research-structures/{id}/`,
  DELETE_RESEARCH_STRUCTURE: `${BASE_URL}api/research-structures/{id}/`,
  ADD_RESEARCH_STRUCTURE: `${BASE_URL}api/research-structures/`,
  RESEARCH_STRUCTURE_DETAILS: `${BASE_URL}api/research-structures/{id}/`,

  //Grad Module - Research Structure Milestones
  RESEARCH_STRUCTURE_MILESTONES_LIST: `${BASE_URL}api/structure-milestones/`,
  UPDATE_RESEARCH_STRUCTURE_MILESTONE: `${BASE_URL}api/structure-milestones/{id}/`,
  DELETE_RESEARCH_STRUCTURE_MILESTONE: `${BASE_URL}api/structure-milestones/{id}/`,
  ADD_RESEARCH_STRUCTURE_MILESTONE: `${BASE_URL}api/structure-milestones/`,
  RESEARCH_STRUCTURE_MILESTONE_DETAILS: `${BASE_URL}api/structure-milestones/{id}/`,

  //Grad Module - Structure Milestone Checklist
  STRUCTURE_MILESTONE_CHECKLIST_LIST: `${BASE_URL}api/milestone-checklists/`,
  UPDATE_STRUCTURE_MILESTONE_CHECKLIST: `${BASE_URL}api/milestone-checklists/{id}/`,
  DELETE_STRUCTURE_MILESTONE_CHECKLIST: `${BASE_URL}api/milestone-checklists/{id}/`,
  ADD_STRUCTURE_MILESTONE_CHECKLIST: `${BASE_URL}api/milestone-checklists/`,
  STRUCTURE_MILESTONE_CHECKLIST_DETAILS: `${BASE_URL}api/milestone-checklists/{id}/`,

  //Grad Module - Intake Structures
  INTAKE_STRUCTURES_LIST: `${BASE_URL}api/intake-structures/`,
  INTAKE_STRUCTURE_DETAILS: `${BASE_URL}api/intake-structures/{id}/`,
  ADD_INTAKE_STRUCTURE: `${BASE_URL}api/intake-structures/`,
  UPDATE_INTAKE_STRUCTURE: `${BASE_URL}api/intake-structures/{id}/`,
  DELETE_INTAKE_STRUCTURE: `${BASE_URL}api/intake-structures/{id}/`,

  //Grad Module - Task Supervisor
  ADD_TASK_SUPERVISOR: `${BASE_URL}api/research-tasks/`,
  TASK_SUPERVISOR_LIST: `${BASE_URL}api/research-tasks/`,
  SUPERVISOR_RATING_LIST: `${BASE_URL}api/evaluation-ratings/`,
  DELETE_TASK_SUPERVISOR: `${BASE_URL}api/research-tasks/{id}/`,
  UPDATE_TASK_SUPERVISOR: `${BASE_URL}api/research-tasks/{id}/`,
  TASK_SUPERVISOR_DETAILS: `${BASE_URL}api/research-tasks/{id}/`,
  GET_TASK_SUPERVISOR_LIST_BY_RESEARCH: `${BASE_URL}api/research-tasks/?research_milestone__research=`,
  GET_RESEARCH_TASKS_BY_STRUCTURE_MILESTONE: `${BASE_URL}api/research-tasks/?research_milestone__structure_milestone=`,

  //Grad Module - Campus Intake
  CAMPUS_INTAKES_LIST: `${BASE_URL}api/campus-intake/`,
  APPLICATION_TYPES: `${BASE_URL}api/grad-application-types/`,
  CHECKLIST: `${BASE_URL}api/application-type-checklists/`,
  CHECKLIST_DELETE: `${BASE_URL}api/application-checklist-submission/{id}/`,
  CHECKLIST_ADD: `${BASE_URL}api/application-checklist-submission/`,
  CHECKLIST_GET: `${BASE_URL}api/application-checklist-submission/{id}/`,

  // Grad Module - Application Interface 1
  APPLICATION_ADD: `${BASE_URL}api/research-applications/`,
  APPLICATION_DELETE: `${BASE_URL}api/research-applications/{id}/`,
  APPLICATION_GET_ID: `${BASE_URL}api/research-applications/{id}/`,
  APPLICATION_UPDATE: `${BASE_URL}api/research-applications/{id}/`,

  // Publications
  AUTHOR_ROLE_GET: `${BASE_URL}api/author-role/`,
  USER_GET: `${BASE_URL}api/users/`,
  RESEARCHERS_GET: `${BASE_URL}api/researchers/`,
  PUBLICATION_ADD: `${BASE_URL}api/publication/`,
  AUTHOR_ADD: `${BASE_URL}api/publication-author/`,

  //Grad Module - Publication Types
  PUBLICATION_TYPES_LIST: `${BASE_URL}api/publication-type/`,
  ADD_PUBLICATION_TYPE: `${BASE_URL}api/publication-type/`,
  UPDATE_PUBLICATION_TYPE: `${BASE_URL}api/publication-type/{id}/`,
  DELETE_PUBLICATION_TYPE: `${BASE_URL}api/publication-type/{id}/`,
  GET_PUBLICATION_TYPE: `${BASE_URL}api/publication-type/{id}/`,

  // Grad Module - Supervisor Types
  SUPERVISOR_TYPES_LIST: `${BASE_URL}api/supervisor-types/`,
  ADD_SUPERVISOR_TYPE: `${BASE_URL}api/supervisor-types/`,
  UPDATE_SUPERVISOR_TYPE: `${BASE_URL}api/supervisor-types/{id}/`,
  DELETE_SUPERVISOR_TYPE: `${BASE_URL}api/supervisor-types/{id}/`,
  GET_SUPERVISOR_TYPE: `${BASE_URL}api/supervisor-types/{id}/`,

  //Defense
  DEFENSE_GET: `${BASE_URL}api/defense/`,

  // Grad Module - Evaluation Rating
  EVALUATION_RATING_LIST: `${BASE_URL}api/evaluation-ratings/`,
  ADD_EVALUATION_RATING: `${BASE_URL}api/evaluation-ratings/`,
  DELETE_EVALUATION_RATING: `${BASE_URL}api/evaluation-ratings/{id}/`,
  EVALUATION_RATING_GET: `${BASE_URL}api/evaluation-ratings/{id}/`,
  UPDATE_EVALUATION_RATING: `${BASE_URL}api/evaluation-ratings/{id}/`,

  //Grad Module - Research Milestones
  GET_RESEARCH_MILESTONES_BY_RESEARCH: `${BASE_URL}api/research-milestones/?research=`,
  ADD_RESEARCH_MILESTONE: `${BASE_URL}api/research-milestones/`,
  GET_RESEARCH_MILESTONES: `${BASE_URL}api/research-milestones/`,
  UPDATE_RESEARCH_MILESTONE: `${BASE_URL}api/research-milestones/{id}/`,
  GET_RESEARCH_MILESTONES_AND_RESEARCH_TASKS_BY_RESEARCH: `${BASE_URL}api/list-research-task/?research=`,

  // Publication
  PUBLICATION_LIST: `${BASE_URL}api/publication/`,
  GET_PUBLICATION: `${BASE_URL}api/publication/{id}/`,
  UPDATE_PUBLICATION: `${BASE_URL}api/publication/{id}/`,
  DELETE_PUBLICATION: `${BASE_URL}api/publication/{id}/`,

  // Publication Type
  PUBLICATION_TYPE_LIST: `${BASE_URL}api/publication-type/`,

  // Author Role
  AUTHOR_ROLE: `${BASE_URL}api/author-role/`,
  ADD_AUTHOR_ROLE: `${BASE_URL}api/author-role/`,
  GET_AUTHOR_ROLE: `${BASE_URL}api/author-role/{id}/`,
  UPDATE_AUTHOR_ROLE: `${BASE_URL}api/author-role/{id}/`,
  DELETE_AUTHOR_ROLE: `${BASE_URL}api/author-role/{id}/`,

  // Users
  USER_LIST: `${BASE_URL}api/users/`,
  ADD_USER: `${BASE_URL}api/users/`,
  GET_USER: `${BASE_URL}api/users/{id}/`,
  DELETE_USER: `${BASE_URL}api/users/{id}/`,
  UPDATE_USER: `${BASE_URL}api/users/{id}/`,

  // User Notification
  USER_NOTIFICATIONS_LIST: `${BASE_URL}api/user-notification/`,
  GET_USER_NOTIFICATION: `${BASE_URL}api/user-notification/{id}`,
  DELETE_USER_NOTIFICATION: `${BASE_URL}api/user-notification/{id}/`,
  UPDATE_USER_NOTIFICATION: `${BASE_URL}api/user-notification/{id}/`,

  //University Restriction
  ADD_UNIVERSITY_RESTRICTION: `${BASE_URL}api/university-restriction/`,
  GET_UNIVERSITY_RESTRICTIONS_BY_AGENT_LEVEL: `${BASE_URL}api/university-restriction/?agent_level=`,
  DELETE_UNIVERSITY_RESTRICTION: `${BASE_URL}api/university-restriction/{id}/`,

  //  Publication Author
  PUBLICATION_AUTHOR_LIST: `${BASE_URL}api/publication-author/`,
  ADD_PUBLICATION_AUTHOR: `${BASE_URL}api/publication-author/`,
  GET_PUBLICATION_AUTHOR: `${BASE_URL}api/publication-author/?publication={id}`,
  UPDATE_PUBLICATION_AUTHOR: `${BASE_URL}api/publication-author/{id}/`,

  // Task Communication Student
  TASK_COMMUNICATION_STUDENT_LIST_BY_RESEARCH_TASK: `${BASE_URL}api/task-communications/?research_task=`,
  ADD_TASK_COMMUNICATION_STUDENT: `${BASE_URL}api/task-communications/`,

  //Task Communication Supervisor
  ADD_TASK_COMMUNICATION_SUPERVISOR: `${BASE_URL}api/task-communications/`,
  TASK_COMMUNICATION_SUPERVISOR_LIST_BY_RESEARCH_TASK: `${BASE_URL}api/task-communications/?research_task=`,

  //Admmission Module - Complaint
  COMPLAINT: `${BASE_URL}api/complaint/`,
  ADD_COMPLAINT: `${BASE_URL}api/complaint/`,
  UPDATE_COMPLAINT: `${BASE_URL}api/complaint/{id}/`,
  DELETE_COMPLAINT: `${BASE_URL}api/complaint/{id}/`,
  // Grad Template
  TEMPLATE: `${BASE_URL}api/templates/`,
  ADD_TEMPLATE: `${BASE_URL}api/templates/`,
  GET_TEMPLATE: `${BASE_URL}api/templates/{id}/`,
  UPDATE_TEMPLATE: `${BASE_URL}api/templates/{id}/`,
  DELETE_TEMPLATE: `${BASE_URL}api/templates/{id}/`,

  //Admmission Module - Agent Application
  AGENT_APPLICATION: `${BASE_URL}api/agent-application/`,
  ADD_AGENT_APPLICATION: `${BASE_URL}api/agent-application/`,
  UPDATE_AGENT_APPLICATION: `${BASE_URL}api/agent-application/{id}/`,
  DELETE_AGENT_APPLICATION: `${BASE_URL}api/agent-application/{id}/`,
  AGENT_APPLICATION_DETAILS: `${BASE_URL}api/agent-application/{id}/`,

  //Admmission Module - Agent University Applications List
  AGENT_UNIVERSITY_APPLICATION: `${BASE_URL}api/agent-university-application/`,
  ADD_AGENT_UNIVERSITY_APPLICATION: `${BASE_URL}api/agent-university-application/`,
  UPDATE_AGENT_UNIVERSITY_APPLICATION: `${BASE_URL}api/agent-university-application/{id}/`,
  DELETE_AGENT_UNIVERSITY_APPLICATION: `${BASE_URL}api/agent-university-application/{id}/`,

  //Admmission Module - Agent Favorite List
  AGENT_FAVORITE: `${BASE_URL}api/agent-favorite/`,
  GET_AGENT_FAVORITE: `${BASE_URL}api/agent-favorite/{id}/`,
  ADD_AGENT_FAVORITE: `${BASE_URL}api/agent-favorite/`,
  UPDATE_AGENT_FAVORITE: `${BASE_URL}api/agent-favorite/{id}/`,
  DELETE_AGENT_FAVORITE: `${BASE_URL}api/agent-favorite/{id}/`,

  //User Attachment - Agent SignUp
  USER_ATTACHMENT: `${BASE_URL}api/user-attachment/`,
  ADD_USER_ATTACHMENT: `${BASE_URL}api/user-attachment/`,
  GET_ATTACHMENT: `${BASE_URL}api/user-attachment/{id}/`,
  ATTACHMENT_UPDATE: `${BASE_URL}api/user-attachment/{id}/`,
  DELETE_USER_ATTACHMENT: `${BASE_URL}api/user-attachment/{id}/`,

  //User Contact - Agent SignUp
  USER_CONTACT: `${BASE_URL}api/user-contact/`,
  GET_USER_CONTACT: `${BASE_URL}api/user-contact/{id}/`,
  UPDATE_USER_CONTACT: `${BASE_URL}api/user-contact/{id}/`,
  DELETE_USER_CONTACT: `${BASE_URL}api/user-contact/{id}/`,

  // Agent
  AGENT_LIST: `${BASE_URL}api/agent/`,
  GET_AGENT: `${BASE_URL}api/agent/{id}/`,
  UPDATE_AGENT: `${BASE_URL}api/agent/{id}/`,
  DELETE_AGENT: `${BASE_URL}api/agent/{id}/`,

  // Experinces - Applicant Profile
  USER_EXPERIENCES: `${BASE_URL}api/experiences/`,
  ADD_USER_EXPERIENCE: `${BASE_URL}api/experiences/`,
  GET_USER_EXPERIENCE: `${BASE_URL}api/experiences/{id}/`,
  UPDATE_USER_EXPERIENCE: `${BASE_URL}api/experiences/{id}/`,
  DELETE_USER_EXPERIENCE: `${BASE_URL}api/experiences/{id}/`,

  // Mediacl Info - Applicant Profile
  MEDICAL_INFO: `${BASE_URL}api/medical-info/`,
  ADD_MEDICAL_INFO: `${BASE_URL}api/medical-info/`,
  GET_MEDICAL_INFO: `${BASE_URL}api/medical-info/{id}/`,
  UPDATE_MEDICAL_INFO: `${BASE_URL}api/medical-info/{id}/`,
  DELETE_MEDICAL_INFO: `${BASE_URL}api/medical-info/{id}/`,

  // Qualification - Applicant Profile
  STUDENT_QUALIFICATION: `${BASE_URL}api/student-qualification/`,
  ADD_STUDENT_QUALIFICATION: `${BASE_URL}api/student-qualification/`,
  GET_STUDENT_QUALIFICATION: `${BASE_URL}api/student-qualification/{id}/`,
  UPDATE_STUDENT_QUALIFICATION: `${BASE_URL}api/student-qualification/{id}/`,
  DELETE_STUDENT_QUALIFICATION: `${BASE_URL}api/student-qualification/{id}/`,

  // Student language - Applicant Profile
  STUDENT_LANGUAGE: `${BASE_URL}api/student-language/`,
  ADD_STUDENT_LANGUAGE: `${BASE_URL}api/student-language/`,
  GET_STUDENT_LANGUAGE: `${BASE_URL}api/student-language/{id}/`,
  UPDATE_STUDENT_LANGUAGE: `${BASE_URL}api/student-language/{id}/`,
  DELETE_STUDENT_LANGUAGE: `${BASE_URL}api/student-language/{id}/`,

  // References - Applicant Profile
  ALL_REFERENCES: `${BASE_URL}api/reference/`,
  ADD_REFERENCE: `${BASE_URL}api/reference/`,
  GET_REFERENCE: `${BASE_URL}api/reference/{id}/`,
  UPDATE_REFERENCE: `${BASE_URL}api/reference/{id}/`,
  DELETE_REFERENCE: `${BASE_URL}api/reference/{id}/`,

  // Visa Info - Applicant Profile
  VISA_INFO: `${BASE_URL}api/visa-info/`,
  ADD_VISA_INFO: `${BASE_URL}api/visa-info/`,
  GET_VISA_INFO: `${BASE_URL}api/visa-info/{id}/`,
  UPDATE_VISA_INFO: `${BASE_URL}api/visa-info/{id}/`,
  DELETE_VISA_INFO: `${BASE_URL}api/visa-info/{id}/`,

  // Award Honors - Applicant Profile
  AWARD_HONORS: `${BASE_URL}api/award-honors/`,
  ADD_AWARD_HONORS: `${BASE_URL}api/award-honors/`,
  GET_AWARD_HONORS: `${BASE_URL}api/award-honors/{id}/`,
  UPDATE_AWARD_HONORS: `${BASE_URL}api/award-honors/{id}/`,
  DELETE_AWARD_HONORS: `${BASE_URL}api/award-honors/{id}/`,

  //   ============= user API =============================
  DASHBORD_API: 'api/veterinary/dashboard?doctor_id=',
  USER_LOGIN: 'http://35.188.156.151/api/token/',
};

export default ApiConfig;
