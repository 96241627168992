import React, { Suspense, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useNavigate, useRoutes } from 'react-router-dom';
import { persistQueryClient } from "react-query/persistQueryClient-experimental"
import { ReactQueryDevtools } from 'react-query/devtools';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { createWebStoragePersistor  } from 'react-query/createWebStoragePersistor-experimental';
import { useSelector } from 'react-redux';
import Themeroutes from './routes/Router';
import ThemeSelector from './layouts/theme/ThemeSelector';
import Loader from './layouts/loader/Loader';



const App = () => {
  const routing = useRoutes(Themeroutes);
  const direction = useSelector((state) => state.customizer.isRTL);
  const isMode = useSelector((state) => state.customizer.isDark);
  const logedIn = localStorage.getItem('logedIn') ? localStorage.getItem('logedIn') : false;
  const navigate = useNavigate();

  useEffect(() => {
    if (!logedIn) {
      navigate('/auth/login');
    }
  }, [logedIn]);

  const cacheTime = 10 * 10000;
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime,
      },
    },
  });
  const localStoragePersistor = createWebStoragePersistor({
    storage: window.localStorage,
  })
  
  const persistQueries = ['fetchUserMenu']
  persistQueryClient({
    queryClient,
    persistor: localStoragePersistor,
    maxAge: cacheTime,
    hydrateOptions: {},
    dehydrateOptions: {
      shouldDehydrateQuery: ({ queryKey }) => {
        return persistQueries.includes(queryKey)
      },
    },
  })

  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<Loader />}>
        <div
          className={`${direction ? 'rtl' : 'ltr'} ${isMode ? 'dark' : ''}`}
          dir={direction ? 'rtl' : 'ltr'}
        >
          <ThemeSelector />
          {routing}
        </div>
      </Suspense>
      {/* <ReactQueryDevtools initialIsOpen={false} position="bottom-right" /> */}
      <ToastContainer/>
    </QueryClientProvider>
  );
};

export default App;