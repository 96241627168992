import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { simpleGetCallWithTokenAxios } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import Loader from "../layouts/loader/Loader";

function ProtectedRouteForDynamicPages(prop) {
  const { element, permissionName } = prop
  const [hasPermission, setHasPermission] = useState(null);

  function extractUrlLinks(menu) {
    let links = [];

    if (menu.links) {
        menu.links.forEach(link => {
            links.push(link.url);
        });
    }

    if (menu.menus) {
        menu.menus.forEach(subMenu => {
            links = links.concat(extractUrlLinks(subMenu));
        });
    }

    return links;
  }

  useEffect(() => {
    async function checkPermission() {
      try {
        const storedResult = localStorage.getItem('dynamicLinks');
        let userAccessPermissionsList = [];
        let permissionExists = '';
        if (storedResult) {
            userAccessPermissionsList = JSON.parse(storedResult);

            permissionExists = userAccessPermissionsList.some(uap => {
              return uap.toLowerCase() === permissionName.toLowerCase()}
          );
          console.log(permissionExists, "permissionExists");
        } else {
            const res = await simpleGetCallWithTokenAxios(ApiConfig.GET_DYNAMIC_USER_MENU_FOR_NON_ADMIN);
            
            let allUrls = [];
            res.data?.modules.forEach(module => {
                module?.menus?.forEach(menu => {
                    allUrls = allUrls.concat(extractUrlLinks(menu));
                });
            });
            localStorage.setItem('dynamicLinks', JSON.stringify(allUrls))

            console.log('No data found in localStorage.');

            permissionExists = allUrls.some(url => 
                url.toLowerCase() === permissionName.toLowerCase()
            );

            // permissionExists = userAccessPermissionsList.some(userPermission => {
            //     const userPermissionName = userPermission?.display_text?.toLowerCase();
            //     return userPermissionName === permissionName.toLowerCase();
            // });    
        }
        setHasPermission(permissionExists);
      } catch (err) {
        console.error("Error fetching user permissions:", err);
        setHasPermission(false);
      }
    }

    checkPermission();
  }, [permissionName]);

  if (hasPermission === null) {
    return <Loader/>;
  }

  if (!hasPermission) {
    return <Navigate to="/auth/404" />;
  }

  return element;
}

export default ProtectedRouteForDynamicPages;
